<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-header>
        <ion-toolbar>
          <ion-title></ion-title>
          <img alt src="https://www.evidenceledger.eu/images/logo.png" width="auto"  height="60px"/>

        </ion-toolbar>
      </ion-header>
      <ion-content class="item-content-sidebar">
        <ion-list class="item-sidebar" id="inbox-list">
          
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item *ngIf="p.title!=='logout'"routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon class="ion-icon-sidebar" aria-hidden="true" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label class="ion-label-sidebar">{{ "menu."+(p.title) | translate }}</ion-label>
            </ion-item>
            <ion-item  *ngIf="p.title==='logout'" button lines="none" detail="false" (click)="logout()">
              <ion-icon class="ion-icon-sidebar" aria-hidden="true" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label class="ion-label-sidebar">{{ "menu."+p.title | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
